/* global Persona */
import cableReady from 'cable_ready';
import ApplicationController from '../../application_controller';
import consumer from '../../../channels/consumer';
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from '../../utilities/loading_spinner';

export default class extends ApplicationController {
  connect() {
    super.connect();
    if (!(this.data.get('can-update') === 'true')) return;

    consumer.subscriptions.create(
      'Store::MembersPortal::IdVerificationChannel',
      {
        received: (data) => {
          if (data.cableReady) cableReady.perform(data.operations);
        },
      },
    );

    document.addEventListener(
      'completeNewID',
      this.completeIdVerification.bind(this),
    );
  }

  // https://docs.withpersona.com/docs/quickstart-embedded-flow
  // https://docs.withpersona.com/docs/embedded-flow-event-handling
  initPersona(event) {
    event.preventDefault();

    if (!(this.data.get('can-update') === 'true')) return;
    showLoadingSpinner();

    const client = new Persona.Client({
      templateId: 'itmpl_Gw5oZEM2Hfq9Vg8JjymnR7TP',
      referenceId: this.data.get('user-email'),
      environment: this.data.get('persona-environment'),
      onReady: () => client.open(),
      onCancel: () => hideLoadingSpinner(),
      onEvent: (name, { inquiryId }) => {
        if (name === 'start') {
          this.updateUserInquiryId(inquiryId);
        }
      },
      onComplete: ({ status }) => {
        if (status === 'failed') {
          $.get('/id_verifications/verification_failed');
          hideLoadingSpinner();
        }
      },
    });
  }

  updateUserInquiryId(inquiryId) {
    $.ajax({
      url: '/id_verifications',
      type: 'PUT',
      beforeSend(xhr) {
        xhr.setRequestHeader(
          'X-CSRF-Token',
          $('meta[name="csrf-token"]').attr('content'),
        );
      },
      data: `persona_inquiry_id=${inquiryId}`,
    });
  }

  completeIdVerification(event) {
    const image = document.getElementById('members-portal-profile-image');
    const downloadingImage = new Image();

    downloadingImage.onload = function () {
      image.src = this.src;
      hideLoadingSpinner();
    };

    downloadingImage.src = event.detail.imageUrl;
  }
}

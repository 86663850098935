import ApplicationController                    from '../../application_controller';
import { handlePromoSuccess, handlePromoError } from '../../utilities/order_summary_promo_application';

export default class extends ApplicationController {
  static targets = [
    'variant',
    'subscriptionType',
    'packsPerMonth',
    'monthsCount',
    'promoCode',
    'consent',
    'agreementBtn'
  ];

  beforeReflex() {
    document.body.classList.add('wait');
    const spinner = document.querySelector('.spinner');
    spinner.classList.remove('invisible', 'opacity-0');
    spinner.classList.add('opacity-90', 'bg-cream');
  }

  afterReflex() {
    document.body.classList.remove('wait');
    const spinner = document.querySelector('.spinner');
    spinner.classList.add('invisible', 'opacity-0');
  }

  connect() {
    super.connect();
    this.handleTooltips();
    this.checkSingleChoice();

    document.addEventListener('promo-update-success', this.handlePromoSuccess);
    document.addEventListener('promo-update-error', this.handlePromoError);
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener('promo-update-success', this.handlePromoSuccess);
    document.removeEventListener('promo-update-error', this.handlePromoError);
  }

  checkSub(e) {
    e.preventDefault();
    const monthsCount = this.monthsCountTarget.dataset.monthsCount;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::Prepayment#show',
          +monthsCount,
          +e.target.dataset.orderId,
          e.target.dataset.quarterlyDiscount
        );
      },
    });
  }

  consentChecked() {
    if (this.consentTarget.checked) {
      this.agreementBtnTarget.removeAttribute('disabled');
    } else {
      this.agreementBtnTarget.setAttribute('disabled', true);
    }
  }

  changeSub(e) {
    e.preventDefault();
    this.element.modal.closeModal();
    let subTypeId = e.target.dataset.subTypeId || 2;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#sub_type_update',
          subTypeId
        );
        console.log(`#sub-type-${subTypeId}`);
        console.log('Target', document.querySelector(`#sub_type_id_${subTypeId}`));
        this.toggleRadioButton({ target: document.querySelector(`#sub_type_id_${subTypeId}`) });
      },
    });
  }

  // https://www.heteroclito.fr/modules/tooltipster/
  handleTooltips() {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    // dose strength tooltip
    $('.dose-tooltip:not(".tooltipstered")').tooltipster({
      theme:    'rugiet',
      position: isMobile ? 'top' : 'right',
      content:  $("<div style='padding: 10px;'><p style='padding-bottom: 4px;'><strong>Your Product for various disease states</strong></p></div>"),
      trigger: 'custom',
      triggerOpen: {
        mouseenter: true,
        touchstart: true
      },
      triggerClose: {
        mouseleave: true,
        tap: true
      }
    });
    // packs per month tooltip
    $('.ppm-tooltip:not(".tooltipstered")').tooltipster({
      theme:    'rugiet',
      position: isMobile ? 'top' : 'right',
      content:  $("<div style='padding: 10px;'><p style='padding-bottom: 4px;'><strong>Cost per month.</strong></p><p>You'll be billed for the full price of</br>your plan and the number of doses</br>and shipped, whether it's a 30-Day Plan</br>(Monthly) or 90-Day plan (Quarterly).</p></div>"),
      trigger: 'custom',
      triggerOpen: {
        mouseenter: true,
        touchstart: true
      },
      triggerClose: {
        mouseleave: true,
        tap: true,
      }
    });
  }

  variantUpdate() {
    const variantId     = this.variantTarget.value;
    const subTypeId     = this.subscriptionTypeTarget.value;
    const packsPerMonth = this.packsPerMonthTarget.value;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#variant_update',
          +variantId,
          +subTypeId,
          +packsPerMonth
        );
      },
    });
  }

  promoUpdate(e) {
    e.preventDefault();
    const promoCode = this.promoCodeTarget.value;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#promo_update',
          promoCode
        );
      },
    });
  }

  subTypeUpdate(e) {
    e.preventDefault();
    const subTypeId = e.target.value;

    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::OrderSummary#sub_type_update',
          subTypeId
        );
      },
    });
  }

  handlePromoSuccess(event) {
    const { result }     = event.detail;
    const promoTextField = document.getElementById('promotion_promo_code');
    const resultTag      = document.getElementById('promo-code-result');
    const promoApplyBtn  = document.getElementById('promo-apply-btn');
    handlePromoSuccess(result, promoTextField, resultTag, promoApplyBtn);
  }

  handlePromoError(event) {
    const { result }    = event.detail;
    const resultTag     = document.getElementById('promo-code-result');
    const promoApplyBtn = document.getElementById('promo-apply-btn');
    handlePromoError(result, resultTag, promoApplyBtn);
  }

  checkSingleChoice() {
    const el = $(".qa-radio");

    el.each((idx) => {
      if ($(el[idx]).prop("checked") === true) {
        $(el[idx])
            .prop("checked", true)
            .siblings("label")
            .css({ "background-color": "#2892F3", color: "#fff" })
            .parent("li")
            .css("border", "2px solid #2892F3");

        $(el[idx])
            .nextAll(".more-info:first")
            .removeClass("hidden ")
            .addClass("border-solid border-2 border-blue-500");
      }
    });

  }

  toggleRadioButton(event) {
    const selected = $(event.target);
    const unselected = selected
        .closest('radiogroup')
        .find("input[type=radio]")
        .not(`#${selected.attr("id")}`);

    selected
        .attr("checked", "checked")
        .siblings("label")
        .css({ "background-color": "#2892F3", color: "#fff" })
        .parent("li")
        .css("border", "2px solid #2892F3");

    unselected
        .removeAttr("checked")
        .siblings("label")
        .attr("style", "")
        .parent("li")
        .css("border", "2px solid #222");
  }
}
